import React from 'react';
import { Instagram, Linkedin, FileText } from 'lucide-react';
import PP from '../assets/images/PP.png'
import FideliPass from '../assets/images/Entier FideliPass.svg'
import NeoXper from '../assets/images/nexpergrd.svg'


interface ProjectCardProps {
  link: string;
  image?: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ link, image }) => {
    const displayLink = link.replace(/https?:\/\/(www\.)?/, '');
  
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="block bg-white rounded-[12px] shadow-sm hover:shadow-md transition-shadow p-4 border border-gray-200"
      >
        <div className="flex items-center mb-3">
          {image && <img src={image} className="w-auto h-7 object-contain" />}
        </div>
        <p className="text-sm text-gray-500">{displayLink}</p>
      </a>
    );
  };
  
  

interface SocialCardProps {
  icon: React.ReactNode;
  username: string;
  link: string;
}

const SocialCard: React.FC<SocialCardProps> = ({ icon, username, link }) => {
    const displayLink = link.replace(/https?:\/\/(www\.)?/, '');
  
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center bg-white rounded-[12px] shadow-sm hover:shadow-md transition-shadow p-4 border border-gray-200"
      >
        {React.cloneElement(icon as React.ReactElement, { className: 'w-5 h-5' })}
        <div className="ml-3">
          <h3 className="font-semibold">{username}</h3>
          <p className="text-sm text-gray-500">{displayLink}</p>
        </div>
      </a>
    );
  };
  
  

const LandingPage: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto p-8 font-sans">
      <header className="flex flex-col md:flex-row items-center mb-12 bg-white rounded-[12px] p-6">
        <img 
          src={PP}
          alt="Gandal Foglia" 
          className="w-32 h-32 rounded-full mb-4 md:mb-0 md:mr-8"
        />
        <div className="text-center md:text-left">
          <h1 className="text-3xl font-bold mb-2">Gandal Foglia</h1>
          <p className="text-lg text-gray-600">Student @ HEC Lausanne</p>
          <p className="text-lg text-gray-600">Founder @ FideliPass</p>
          <p className="text-lg text-gray-600">Co-Founder @ Neoxper</p>
        </div>
      </header>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <ProjectCard 
            link="https://www.fidelipassapp.com" 
            image={FideliPass}
          />
          <ProjectCard 
            link="https://www.neoxper.ch" 
            image={NeoXper}
          />
        </div>
      </section>

      <section>
        <h2 className="text-2xl font-bold mb-4">Socials</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <SocialCard 
            icon={<FileText />}
            username="Gandal Foglia - Read.cv" 
            link="https://www.read.cv/gandal" 
          />
          <SocialCard 
            icon={<Linkedin />}
            username="Gandal Foglia" 
            link="https://www.linkedin.com/in/gandalfoglia" 
          />
          <SocialCard 
            icon={<Instagram />}
            username="@gandalfoglia" 
            link="https://www.instagram.com/gandalfoglia" 
          />
        </div>
      </section>
    </div>
  );
};

export default LandingPage;